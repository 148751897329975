import * as React from "react";
import styled from "styled-components";
import { useState } from "react";
import get from "lodash/get";
import { Form } from "react-bootstrap";
import { HiSearch } from "react-icons/hi";
import { colors } from "./colorVariables";
import SearchResults from "./SearchResults";

interface Props {
  open?: boolean | null;
  mobile?: boolean | null;
}

const FormWrapper = styled.div<Props>`
  width: 20vw;
  text-align: ${({ mobile }) => (mobile ? "center" : "undefined")};
  margin: ${({ mobile }) => (mobile ? "auto" : "undefined")};
  position: relative;
  display: flex;
  justify-content: end;
`;

const SearchFormWrapper = styled.div<Props>`
  position: relative;
  ${({ mobile }) => (mobile ? "margin-top: 1rem;" : "")}
  input {
    opacity: ${({ open }) => (open ? 1 : 0)};
    width: ${({ open, mobile }) => (mobile ? "100%" : open ? "90%" : "10%")};
    margin-left: ${({ open, mobile }) =>
      mobile ? undefined : open ? "10%" : "90%"};
    transition: all 0.3s ease;
  }
  button {
    ${({ mobile }) =>
      mobile ? `text-align: center; border-radius: .5rem;` : ``}
    position: absolute;
    top: 5px;
    right: ${({ mobile, open }) =>
      mobile ? (open ? 0 : "calc(50% - 16px)") : 0};
  }
  input {
    background: ${get(colors, "lightGray.hex")};
  }
`;

const SearchButton = styled.button<Props>`
  background: ${({ open }) => (open ? `none` : get(colors, "lightGray.hex"))};
  border: none;
  display: box;
  transition: all 0.3s ease;
  color: ${get(colors, "gray.hex")};
  &:hover {
    color: ${get(colors, "blue.hex")}
  }
`;

const Search = ({
  mobile,
  isSearchOpen,
  setIsSearchOpen
}: {
  mobile?: boolean;
  isSearchOpen: boolean;
  setIsSearchOpen: Function;
}) => {
  // const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
  const [searchVal, setSearchVal] = useState<string>("");

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    const val = get(e, `target.value`, "");
    setSearchVal(val);
  };

  return (
    <FormWrapper mobile={mobile}>
      <Form onSubmit={handleSearch}>
        <SearchFormWrapper open={isSearchOpen} mobile={mobile}>
          <Form.Control
            placeholder="Search..."
            value={searchVal}
            onChange={handleSearch}
          />
          <SearchButton onClick={toggleSearch} open={isSearchOpen}>
            <HiSearch
              aria-label="Search"
              style={{ fontSize: "1.5rem" }}
            />
          </SearchButton>
        </SearchFormWrapper>
      </Form>
      <SearchResults
        query={searchVal !== "" ? searchVal : ""}
        mobile={mobile}
      />
    </FormWrapper>
  );
};

export default Search;
