import * as React from "react";

const FONT_FAMILY_SCRIPT = `Vujahday Script, serif`;
const FONT_FAMILY_SANS = `Heebo, sans-serif`;

const FONT_WEIGHT_LIGHT = 300;
const FONT_WEIGHT_NORMAL = 500;
const FONT_WEIGHT_BOLD = 700;

const FONT_SIZE_H1 = 54;
const FONT_SIZE_H2 = 48;
const FONT_SIZE_H3 = 44;
const FONT_SIZE_H4 = 28;
const FONT_SIZE_H5 = 22;
const FONT_SIZE_H6 = 19;
const FONT_SIZE_P = 16;
const FONT_SIZE_BUTTON = 18;
const FONT_SIZE_FOOTER = 14;
const FONT_SIZE_EYEBROW = 20;

export const Typography = {
  H1: React.forwardRef(
    (
      {
        children,
        style,
        className
      }: {
        children: React.ReactChild[] | React.ReactChild;
        style?: object;
        className?: string;
      },
      ref: React.ForwardedRef<HTMLHeadingElement>
    ) => {
      return (
        <h1
          className={className}
          style={{
            fontFamily: FONT_FAMILY_SANS,
            fontWeight: FONT_WEIGHT_NORMAL,
            fontSize: FONT_SIZE_H1,
            ...style
          }}
          ref={ref}>
          {children}
        </h1>
      );
    }
  ),

  H2: React.forwardRef(
    (
      {
        children,
        style,
        className
      }: {
        children: React.ReactChild[] | React.ReactChild;
        style?: object;
        className?: string;
      },
      ref: React.ForwardedRef<HTMLHeadingElement>
    ) => {
      return (
        <h2
          className={className}
          style={{
            fontFamily: FONT_FAMILY_SCRIPT,
            fontWeight: FONT_WEIGHT_NORMAL,
            textTransform: "uppercase",
            fontSize: FONT_SIZE_H2,
            ...style
          }}
          ref={ref}>
          {children}
        </h2>
      );
    }
  ),

  H3: React.forwardRef(
    (
      {
        children,
        style,
        className
      }: {
        children: React.ReactChild[] | React.ReactChild;
        style?: object;
        className?: string;
      },
      ref: React.ForwardedRef<HTMLHeadingElement>
    ) => {
      return (
        <h3
          className={className}
          style={{
            fontFamily: FONT_FAMILY_SANS,
            fontWeight: FONT_WEIGHT_BOLD,
            fontSize: FONT_SIZE_H3,
            ...style
          }}
          ref={ref}>
          {children}
        </h3>
      );
    }
  ),

  H4: React.forwardRef(
    (
      {
        children,
        style,
        className
      }: {
        children: React.ReactChild[] | React.ReactChild;
        style?: object;
        className?: string;
      },
      ref: React.ForwardedRef<HTMLHeadingElement>
    ) => {
      return (
        <h4
          className={className}
          style={{
            fontFamily: FONT_FAMILY_SANS,
            fontWeight: FONT_WEIGHT_BOLD,
            fontSize: FONT_SIZE_H4,
            ...style
          }}
          ref={ref}>
          {children}
        </h4>
      );
    }
  ),

  H5: React.forwardRef(
    (
      {
        children,
        style,
        className
      }: {
        children: React.ReactChild[] | React.ReactChild;
        style?: object;
        className?: string;
      },
      ref: React.ForwardedRef<HTMLHeadingElement>
    ) => {
      return (
        <h5
          className={className}
          style={{
            fontFamily: FONT_FAMILY_SANS,
            fontWeight: FONT_WEIGHT_BOLD,
            fontSize: FONT_SIZE_H5,
            ...style
          }}
          ref={ref}>
          {children}
        </h5>
      );
    }
  ),

  H6: React.forwardRef(
    (
      {
        children,
        style,
        className
      }: {
        children: React.ReactChild[] | React.ReactChild;
        style?: object;
        className?: string;
      },
      ref: React.ForwardedRef<HTMLHeadingElement>
    ) => {
      return (
        <h6
          className={className}
          style={{
            fontFamily: FONT_FAMILY_SANS,
            fontWeight: FONT_WEIGHT_BOLD,
            fontSize: FONT_SIZE_H6,
            ...style
          }}
          ref={ref}>
          {children}
        </h6>
      );
    }
  ),

  P: React.forwardRef(
    (
      {
        children,
        style,
        className
      }: {
        children: React.ReactChild[] | React.ReactChild;
        style?: object;
        className?: string;
      },
      ref: React.ForwardedRef<HTMLHeadingElement>
    ) => {
      return (
        <div
          className={className}
          style={{
            fontFamily: FONT_FAMILY_SANS,
            fontWeight: FONT_WEIGHT_LIGHT,
            fontSize: FONT_SIZE_P,
            // maxWidth: `700px`,
            ...style
          }}
          ref={ref}>
          {children}
        </div>
      );
    }
  ),

  Button: React.forwardRef(
    (
      {
        children,
        style,
        className
      }: {
        children?: React.ReactChild[] | React.ReactChild;
        style?: object;
        className?: string;
      },
      ref: React.ForwardedRef<HTMLHeadingElement>
    ) => {
      return (
        <p
          className={className}
          style={{
            fontFamily: FONT_FAMILY_SANS,
            fontWeight: FONT_WEIGHT_BOLD,
            fontSize: FONT_SIZE_BUTTON,
            ...style
          }}
          ref={ref}>
          {children}
        </p>
      );
    }
  ),

  Footer: React.forwardRef(
    (
      {
        children,
        style,
        className
      }: {
        children: React.ReactChild[] | React.ReactChild;
        style?: object;
        className?: string;
      },
      ref: React.ForwardedRef<HTMLHeadingElement>
    ) => {
      return (
        <p
          className={className}
          style={{
            fontFamily: FONT_FAMILY_SANS,
            fontWeight: FONT_WEIGHT_LIGHT,
            fontSize: FONT_SIZE_FOOTER,
            ...style
          }}
          ref={ref}>
          {children}
        </p>
      );
    }
  ),

  Eyebrow: React.forwardRef(
    (
      {
        children,
        style,
        className
      }: {
        children: React.ReactChild[] | React.ReactChild;
        style?: object;
        className?: string;
      },
      ref: React.ForwardedRef<HTMLHeadingElement>
    ) => {
      return (
        <p
          className={className}
          style={{
            fontFamily: FONT_FAMILY_SCRIPT,
            fontWeight: FONT_WEIGHT_NORMAL,
            fontSize: FONT_SIZE_EYEBROW,
            ...style
          }}
          ref={ref}>
          {children}
        </p>
      );
    }
  )
};
