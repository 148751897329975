import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Typography } from "./Typography";
import { WmkLink } from "wmk-lib";
import { useState, useEffect } from "react";
import { colors } from "./colorVariables";
import { AiOutlineHome } from "react-icons/ai";
import { BsPeople, BsHeart } from "react-icons/bs";
import { IoHandRightOutline } from "react-icons/io5";
import { FaChevronDown } from "react-icons/fa";

interface Props {
  open: string | null;
  mobile?: boolean | null;
}

const StyledMenuButton = styled.button<Props>`
  &:hover {
    color: ${colors?.blue?.hex};
  }
  border: none;
  background: none;
  display: flex;
  width: auto !important;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  h5 {
    margin: 0;
  }
  &:before {
    content: "|";
    position: absolute;
    left: -10%;
    top: -48%;
    font-size: 1.4rem;
    color: ${colors?.blue?.hex};
  }
  .chevron {
    color: ${colors.green.hex};
    transition: all 0.3s ease;
    transform: ${({ open }) =>
      open ? "scale(1) rotate(180deg)" : "scale(1) rotate(0)"};
  }
  & > div {
    transition: all 0.3s ease;
  }
  @media only screen and (max-width: 1200px) {
    h5 {
      font-size: 14px !important;
    }
  }
`;

const StyledDropdownMenu = styled(Col)<Props>`
  position: absolute;
  padding: 1rem;
  left: 0;
  position: absolute;
  top: 8.8rem;
  transition: all 0.3s ease;
  opacity: ${({ open }) => (open ? 1 : 0)};
  transform: ${({ open }) => (open ? "positionY(-100vw)" : "positionY(0)")};
  z-index: ${({ open }) => (open ? 1 : -1)};
  background: ${colors.lightGray.hex};
  //box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  width: 100vw;
  .menu-item-col {
    margin: 1rem 0;
  }
  .menu-item-col > *:before {
    content: "";
  }
  @media only screen and (max-width: 1200px) {
    top: 8.2rem;
  }
`;

const StyledMenuLink = styled(Col)<Props>`
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  h5 {
    color: ${({ xs }) => (xs ? colors?.blue?.hex : `inherit`)};
    margin: 0;
  }
  a {
    text-decoration: none;
    color: black;
    &:hover {
      color: ${colors?.blue?.hex};
    }
  }
  &:before {
    content: "|";
    position: absolute;
    left: -10%;
    top: -48%;
    font-size: 1.4rem;
    color: ${colors?.blue?.hex};
  }
`;

const MobileMenuLink = styled(Col)`
  text-transform: uppercase;
  a {
    text-decoration: none;
    color: ${colors?.gray?.hex};
    &:hover {
      color: ${colors?.blue?.hex};
    }
    h5 {
      margin: 0;
    }
    .icon-links {
      height: 3rem;
    }
    .icon-links > div {
      display: flex;
      justify-content: start;
      align-items: center;
    }
    .icon-links > .col-2 {
      justify-content: center;
    }
  }
`;

const StyledNavRow = styled(Row)`
  background: ${colors.medGray.hex};
  justify-content: start;
  height: 3rem;
  .menu-button-wrapper {
    transition: all 0.3s ease;
  }
  .menu-button-wrapper:first-child > *:first-child:before {
    content: "";
  }
  @media only screen and (max-width: 1200px) {
    height: 2.5rem;
  }
`;

export const getLinkPath = (link: menuPage) => {
  const parentPath = link?.parentPath;
  const slug = link?.slug;
  const fullPath =
    parentPath && slug && parentPath !== "/"
      ? `${parentPath}/${slug}`
      : slug
      ? `/${slug}`
      : `#`;
  return fullPath;
};

export interface menuPage {
  parentPath: string;
  slug: string;
  title: string;
}

const MenuItemLink = ({
  to,
  text,
  xs,
  i,
  viewWidth
}: {
  to: string;
  text: string;
  xs?: string;
  i?: number;
  viewWidth: number;
}) => {
  const icons = [
    <AiOutlineHome />,
    <BsPeople />,
    <BsHeart />,
    <IoHandRightOutline />
  ];
  return viewWidth > 768 ? (
    <StyledMenuLink xs={xs} to={to}>
      <WmkLink to={to} style={{ display: "block" }}>
        <Typography.H5 style={{ fontSize: ".95rem" }}>{text}</Typography.H5>
      </WmkLink>
    </StyledMenuLink>
  ) : (
    <MobileMenuLink to={to}>
      <WmkLink to={to} style={{ display: "block" }}>
        <Row className="icon-links">
          <Col xs={2}>
            <Typography.H5 style={{ fontSize: "2rem" }}>
              {i ? icons[i] : icons[2]}
            </Typography.H5>
          </Col>
          <Col xs={10}>
            <Typography.H5 style={{ fontSize: ".95rem" }}>{text}</Typography.H5>
          </Col>
        </Row>
      </WmkLink>
    </MobileMenuLink>
  );
};

const Intermediary = ({
  links,
  viewWidth
}: {
  links: [{ title: string; parentPage: menuPage; links: menuPage[] }];
  viewWidth: number;
}) => {
  const [isOpen, setIsOpen] = useState<string | null>(null);

  const handleDropdown = (to: string | null) => {
    if (to === isOpen) {
      setIsOpen(null);
    } else {
      setIsOpen(to);
    }
  };

  return (
    <>
      {Array.isArray(links) &&
        links.length &&
        links.map((menu, i) => {
          return (
            <MenuButton
              links={menu.links}
              text={menu.title}
              to={getLinkPath(menu.parentPage)}
              openDropdownSelection={isOpen}
              key={`${menu.title} ${i}`}
              handler={handleDropdown}
              viewWidth={viewWidth}
              i={i}
              title={menu.title}
            />
          );
        })}
    </>
  );
};

const DropdownMenu = ({
  open,
  pageLinks,
  to,
  viewWidth,
  parentTitle
}: {
  open: string | null;
  pageLinks: menuPage[];
  to: string;
  viewWidth: number;
  parentTitle: string;
}) => {
  return open === to ? (
    <StyledDropdownMenu open={open === to ? open : null} to={to}>
      <Container fluid>
        <Row>
          {Array.isArray(pageLinks) && pageLinks.length
            ? pageLinks.map((link, i) => {
                return (
                  <Col key={link.title + i} md={12} className="menu-item-col">
                    <MenuItemLink
                      to={link.url ? link.url : getLinkPath(link)}
                      text={
                        link.title === parentTitle
                          ? "Overview"
                          : link.title
                          ? link.title
                          : link.linkText
                      }
                      // target={get(link, `target)`)}
                      viewWidth={viewWidth}
                      i={i}
                    />
                  </Col>
                );
              })
            : null}
        </Row>
      </Container>
    </StyledDropdownMenu>
  ) : null;
};

const MenuButton = ({
  text,
  to,
  links,
  openDropdownSelection,
  handler,
  i,
  viewWidth,
  title
}: {
  text: string;
  to: string;
  links: menuPage[];
  openDropdownSelection: string | null;
  handler: Function;
  i: number;
  viewWidth: number;
  title: string;
}) => {
  // const [isOpen, setIsOpen] = useState<string | null>(null);
  // useEffect(() => {
  //   console.log(isOpen);
  // }, [isOpen]);

  // const handleDropdown = (to: string | null) => {
  //   if (to === isOpen) {
  //     setIsOpen(null);
  //   } else {
  //     setIsOpen(to);
  //   }
  // };

  return viewWidth > 768 ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "auto"
      }}
      className="menu-button-wrapper">
      {/* {openDropdownSelection !== to ? (
        <StyledMenuButton onClick={() => handleDropdown(to)} open={isOpen}>
          <Typography.H5 style={{ fontSize: ".95rem" }}>{text}</Typography.H5>
        </StyledMenuButton>
      ) : (
        <MenuItemLink to={to} text={text} xs="auto" />
        <StyledMenuLink>
          <Typography.H5 style={{ fontSize: ".95rem" }}>{text}</Typography.H5>
        </StyledMenuLink>
      )} */}
      <StyledMenuButton
        onClick={() => handler(to)}
        open={openDropdownSelection === to ? openDropdownSelection : ""}>
        <Typography.H5 style={{ fontSize: ".95rem" }}>{text}</Typography.H5>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "0.5rem"
          }}
          className="chevron">
          <FaChevronDown />
        </div>
      </StyledMenuButton>
      <DropdownMenu
        open={openDropdownSelection}
        pageLinks={links}
        to={to}
        viewWidth={viewWidth}
        parentTitle={title}
      />
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        // alignItems: "center",
        width: "auto"
      }}
      className="menu-button-wrapper">
      <MenuItemLink to={to} text={text} xs="auto" i={i} viewWidth={viewWidth} />
    </div>
  );
};

export const NavBar = ({
  // title,
  links
}: {
  links: [{ title: string; parentPage: menuPage; links: menuPage[] }];
  // title: string;
}) => {
  // const [isOpen, setIsOpen] = useState<string | null>(null);
  const [viewWidth, setViewWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const winWidth = window.innerWidth;
      setViewWidth(winWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // const handleDropdown = (to: string | null) => {
  //   if (to === isOpen) {
  //     setIsOpen(null);
  //   } else {
  //     setIsOpen(to);
  //   }
  // };

  return (
    <Container fluid style={{ padding: "0" }}>
      {viewWidth > 768 && (
        <StyledNavRow>
          <Intermediary links={links} viewWidth={viewWidth} />
          {/* {Array.isArray(links) &&
            links.length &&
            links.map((menu, i) => {
              return (
                <MenuButton
                  links={get(menu, "links")}
                  text={get(menu, "title")}
                  to={getLinkPath(get(menu, "parentPage"))}
                  openDropdownSelection={isOpen}
                  key={`${get(menu, "title")} ${i}`}
                  handler={handleDropdown}
                  i={i}
                />
              );
            })} */}
        </StyledNavRow>
      )}
      {viewWidth <= 768 && (
        <Col>
          {Array.isArray(links) &&
            links.length &&
            links.map((menu, i) => {
              return (
                <MenuButton
                  links={menu?.links}
                  text={menu?.title}
                  to={getLinkPath(menu?.parentPage)}
                  // openDropdownSelection={isOpen}
                  key={`${menu?.title} ${i}`}
                  i={i}
                  viewWidth={viewWidth}
                />
              );
            })}
        </Col>
      )}
    </Container>
  );
};

// export default NavBar;
