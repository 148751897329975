export const colors = {
  blue: {
    hex: `#00ABBE`,
    rgb: `0, 171, 190`,
    r: 0,
    g: 171,
    b: 190
  },
  darkBlue: {
    hex: `#008998`,
    rgb: `0, 137, 152`,
    r: 0,
    g: 137,
    b: 152
  },
  green: {
    hex: `#8EC86D`,
    rgb: `142, 200, 109`,
    r: 142,
    g: 200,
    b: 109
  },
  darkGreen: {
    hex: `#72A057`
    // rgb: `142, 200, 109`,
    // r: 142,
    // g: 200,
    // b: 109
  },
  orange: {
    hex: `#DB9F62`,
    rgb: `219, 159, 98`,
    r: 219,
    g: 159,
    b: 98
  },
  black: {
    hex: `#454545`,
    rgb: `69, 69, 69`,
    r: 69,
    g: 69,
    b: 69
  },
  lightBlue: {
    hex: `#B2E6EB`,
    rgb: `178, 230, 235`,
    r: 178,
    g: 230,
    b: 235
  },
  gray: {
    hex: `#A2A2A2`,
    rgb: `162, 162, 162`,
    r: 162,
    g: 162,
    b: 162
  },
  // lightGray: {
  //   hex: `#00000019`,
  //   rgb: `0, 0, 0`,
  //   r: 0,
  //   g: 0,
  //   b: 0
  // }
  lightGray: {
    hex: `#F4F4F4`,
    rgb: `224, 224, 224`,
    r: 224,
    g: 224,
    b: 224
  },
  medGray: {
    hex: `#E6E6E6`,
    rgb: `230, 230, 230`,
    r: 230,
    g: 230,
    b: 230
  },
  footerBlue: {
    hex: `#45C2C5`,
    rgb: `rgba(69, 194, 197, 1)`,
    r: 69,
    g: 194,
    b: 197
  }
};
