/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import Header from "./header";
import Footer from "./footer";
import { ReactElement } from "react-markdown/lib/react-markdown";

const Layout = ({ children }: { children: ReactElement[] }) => {

  return (
    <>
      <Header />
      <div>
        <main>{children}</main>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
