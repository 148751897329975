import * as React from "react";
import styled from "styled-components";
// import { WmkLink } from "wmk-lib";
import { colors } from "./colorVariables";
import { Typography } from "./Typography";
import get from "lodash/get";
import { WmkLink } from "wmk-link";

interface Props {
  header?: boolean;
}

const StyledButtonWrapper = styled.div<Props>`
  display: ${({ header }) => (header ? `flex` : `inline-block`)};
  position: relative;
  transition: all 0.5s ease;
  padding: 0.7rem 0;
  overflow: hidden;
  div:hover {
  }
  .circle {
    position: absolute;
    bottom: -20%;
    right: 0;
    z-index: 0;
  }
  a {
    padding: 1rem 2rem;
    background: ${get(colors, "green.hex")};
    position: relative;
    text-decoration: none;
    white-space: nowrap;
    .circle {
      width: 0vw;
      height: 0vw;
      background: rgba(${get(colors, `blue.rgb`)}, 1);
      border-radius: 10vw 0 0 0;
      animation: slideout 0.3s linear 0s;
      @keyframes slideout {
        from {
          right: 0%;
          width: 50vw;
          height: 50vw;
        }

        to {
          right: 0%;
          width: 0vw;
          height: 0vw;
        }
      }
    }
  }
  a:hover {
    .circle {
      width: 100vw;
      height: 100vw;
      background: rgba(${get(colors, `blue.rgb`)}, 1);
      border-radius: 10vw 0 0 0;
      animation: slidein 0.3s linear 0s;
      @keyframes slidein {
        from {
          right: 0%;
          width: 0%;
          height: 0%;
        }

        to {
          right: 0%;
          width: 100vw;
          height: 100vw;
        }
      }
    }
  }
  p {
    margin: 0;
    padding: 0;
    display: inline-block;
    color: white;
    z-index: 10;
    position: relative;
    ${({ header }) =>
      header
        ? `
      width: 100vw; 
      display: flex;
      justify-content: center;
      `
        : ""};
  }
`;

const StyledButtonWrapperWhite = styled.div<Props>`
  display: ${({ header }) => (header ? `flex` : `inline-block`)};
  position: relative;
  transition: all 0.5s ease;
  padding: 0.7rem 0;
  overflow: hidden;
  div:hover {
  }
  .circle {
    position: absolute;
    bottom: -20%;
    right: 0;
    z-index: 0;
  }
  a {
    padding: 1rem 2rem;
    background: white;
    position: relative;
    text-decoration: none;
    white-space: nowrap;
    .circle {
      width: 0vw;
      height: 0vw;
      background: rgba(${get(colors, `blue.rgb`)}, 1);
      border-radius: 10vw 0 0 0;
      animation: slideout 0.3s linear 0s;
      @keyframes slideout {
        from {
          right: 0%;
          width: 50vw;
          height: 50vw;
        }

        to {
          right: 0%;
          width: 0vw;
          height: 0vw;
        }
      }
    }
  }
  a:hover {
    .circle {
      width: 100vw;
      height: 100vw;
      background: rgba(${get(colors, `blue.rgb`)}, 1);
      border-radius: 10vw 0 0 0;
      animation: slidein 0.3s linear 0s;
      @keyframes slidein {
        from {
          right: 0%;
          width: 0%;
          height: 0%;
        }

        to {
          right: 0%;
          width: 100vw;
          height: 100vw;
        }
      }
    }
    p {
      color: white;
      transition: all 0.3s ease;
    }
  }
  p {
    margin: 0;
    padding: 0;
    display: inline-block;
    color: white;
    z-index: 10;
    position: relative;
    transition: all 0.3s ease;
    color: ${colors.blue.hex}
    ${({ header }) =>
      header
        ? `
      width: 100vw; 
      display: flex;
      justify-content: center;
      `
        : ""};
  }
  span {
    color: #454545;
  }
`;

const LargeButtonWrapper = styled.div`
  display: inline-block;
  position: relative;
  transition: all 0.3s ease;
  padding: 0.9rem 0;
  overflow: hidden;
  .circle {
    position: absolute;
    bottom: -20%;
    right: 0;
    z-index: 0;
  }
  a {
    padding: 1.8rem 3rem;
    background: ${get(colors, "green.hex")};
  }
  position: relative;
    .circle {
      width: 0vw;
      height: 0vw;
      background: rgba(${get(colors, `blue.rgb`)}, 1);
      border-radius: 10vw 0 0 0;
      animation: slideout 0.2s linear 0s;
      @keyframes slideout {
        from {
          right: 0%;
          width: 80vw;
          height: 80vw;
        }

        to {
          right: 0%;
          width: 0vw;
          height: 0vw;
        }
      }
    }
  }
  a:hover {
    .circle {
      width: 80vw;
      height: 80vw;
      background: rgba(${get(colors, `blue.rgb`)}, 1);
      border-radius: 10vw 0 0 0;
      animation: slidein 0.2s linear 0s;
      @keyframes slidein {
        from {
          right: 0%;
          width: 0%;
          height: 0%;
        }

        to {
          right: 0%;
          width: 80vw;
          height: 80vw;
        }
      }
    }
  }
  p {
    margin: 0;
    padding: 0;
    display: inline-block;
    color: white;
    font-size: 28px !important;
    font-weight: 400 !important;
    z-index: 10;
    position: relative;
  }
  span {
    color: #454545;
  }
  @media only screen and (max-width: 1200px) {
    padding: 0.9rem 0;
    a {
      padding: 1.5rem 3rem;
    }
    p {
      font-size: 22px !important;
    }
  }
`;

const HeaderButtonWrapper = styled.div`
  display: flex;
  position: relative;
  transition: all 0.3s ease;
  overflow: hidden;
  .circle {
    position: absolute;
    bottom: -20%;
    right: 0;
    z-index: 0;
  }
  a {
    padding: 1rem 0;
    background: ${get(colors, "green.hex")};
    position: relative;
    text-decoration: none;
    transition: all 0.3s ease;
  }
  a:hover {
    background: ${get(colors, "blue.hex")};
    transition: all 0.3s ease;
  }
  p {
    margin: 0;
    padding: 0;
    display: inline-block;
    color: white;
    z-index: 10;
    position: relative;
    width: 100vw;
    display: flex;
    justify-content: center;
  }
`;

const DonateButton = ({
  to,
  target,
  large,
  header,
  children,
  white
}: {
  to: string;
  target?: string;
  large?: boolean;
  header?: boolean;
  children?: React.ReactChild;
  white: boolean;
}) => {
  const buttonDisplay = large ? (
    <LargeButtonWrapper>
      <WmkLink to={to} target={target}>
        <div className="circle"></div>
        <Typography.Button>
          Donate <span>Today</span>
        </Typography.Button>
      </WmkLink>
    </LargeButtonWrapper>
  ) : header ? (
    <HeaderButtonWrapper>
      <WmkLink to={to} target={target}>
        <div className="circle"></div>
        <Typography.Button>{children}</Typography.Button>
      </WmkLink>
    </HeaderButtonWrapper>
  ) : white ? (
    <StyledButtonWrapperWhite>
      <WmkLink to={to} target={target}>
        <div className="circle"></div>
        <Typography.Button>Donate <span>Today</span></Typography.Button>
      </WmkLink>
    </StyledButtonWrapperWhite>
  ) : (
    <StyledButtonWrapper>
      <WmkLink to={to} target={target}>
        <div className="circle"></div>
        <Typography.Button>{children}</Typography.Button>
      </WmkLink>
    </StyledButtonWrapper>
  );
  return buttonDisplay;
};

export default DonateButton;
