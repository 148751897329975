import * as React from "react";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import ITLogo from "../ITLogo";
import { Typography } from "../ui/Typography";
import { NavBar } from "../ui/NavBar";
import DonateButton from "../ui/DonateButton";
import { useStaticQuery, graphql } from "gatsby";
import { colors } from "../ui/colorVariables";
import Search from "../ui/Search";
import { useState, useEffect } from "react";
import Burger from "./BurgerMenu/Burger";
import { WmkLink } from "wmk-lib";

interface Props {
  $isSearchOpen: boolean;
}

const StyledHeader = styled.header`
  h1 {
    margin: 0 0 0 1rem;
    color: ${colors.blue.hex};
    position: relative;
    bottom: -17%;
  }
  .container-fluid {
    padding: 0;
  }
  .search-col {
    display: flex;
    justify-content: flex-end;
  }
  .nav-bar-col {
    padding: 0;
    display: flex;
    align-items: end;
  }
  .nav-bar-col:before {
    content: " ";
    background-color: ${colors.medGray.hex};
    height: 3rem;
    width: 25%;
    position: absolute;
    left: 0;
    @media only screen and (max-width: 1200px) {
      height: 2.5rem;
    }
  }
  .donate-button-col {
    display: flex;
    align-items: end;
  }
  .logo-col {
    display: flex;
    align-items: center;
  }
  .row {
    width: 100%;
    margin: 0;
  }
  @media only screen and (max-width: 1200px) {
    .nav-bar-col {
      h5 {
      }
    }
  }
`;

const ContactLinkWrapper = styled(Col)<Props>`
  position: absolute;
  transition: all 0.3s ease;
  right: ${({ $isSearchOpen }) => ($isSearchOpen ? `12rem` : `3rem`)};
  justify-content: end;
  display: flex;
  width: 15vw;
  z-index: 1;
  top: 1vh;
  a {
    text-decoration: none;
    color: ${colors.gray.hex};
  }
  a:hover {
    color: ${colors.blue.hex};
  }
`;

const MobileHeader = styled.header`
  .burger-logo-col {
    margin: 1.5rem;
    display: flex;
    justify-content: space-between;
  }
  .donate-button-col {
    padding: 0;
  }
`;

const Header = () => {
  const data = useStaticQuery(graphql`
    {
      mainMenu: contentfulMenu(title: { eq: "Main Menu" }) {
        ...NodeMenuFields
      }
      footer: contentfulMenu(title: { eq: "Footer Menu" }) {
        ...NodeMenuFields
      }
      site {
        siteMetadata {
          title
        }
      }
      donatePage: contentfulPage(title: { eq: "Donate Today" }) {
        parentPath
        slug
      }
      contactPage: contentfulPage(title: { eq: "Contact Us" }) {
        parentPath
        slug
        title
      }
      global: contentfulGlobalOptions(title: { eq: "India Transformed!" }) {
        title
        address {
          address
        }
        phone
        contactEmail
      }
    }
  `);

  const [viewWidth, setViewWidth] = useState(0);
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
  useEffect(() => {
    const handleResize = () => {
      const winWidth = window.innerWidth;
      setViewWidth(winWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [isBurgerOpen, setIsBurgerOpen] = useState(false);
  const toggleBurger = () => {
    setIsBurgerOpen(!isBurgerOpen);
    //console.log("isBurgerOpen: ", isBurgerOpen);
  };

  const getLink = (page: { parentPath: string; slug: string }) => {
    const parentPath = page?.parentPath;
    const slug = page?.slug;
    const fullPath =
      parentPath && slug && parentPath !== "/"
        ? `${parentPath}/${slug}`
        : slug
        ? `/${slug}`
        : `#`;
    return fullPath;
  };

  const getDisplay = () => {
    if (viewWidth > 992) {
      return (
        <StyledHeader>
          <Container fluid>
            <Row>
              <Col className="logo-col" xs={{ offset: 1 }}>
                <WmkLink to="/">
                  <ITLogo width="114" height="70" />
                </WmkLink>
                <Typography.H1
                  style={{ fontSize: "28px", textTransform: "uppercase" }}>
                  {data?.site?.siteMetadata?.title}
                </Typography.H1>
              </Col>
              <ContactLinkWrapper $isSearchOpen={isSearchOpen}>
                <WmkLink to={getLink(data?.contactPage)}>
                  <Typography.P
                    style={{ textTransform: "uppercase", fontWeight: 400 }}>
                    {data?.contactPage?.title}
                  </Typography.P>
                </WmkLink>
              </ContactLinkWrapper>
              <Col className="search-col">
                <Search
                  isSearchOpen={isSearchOpen}
                  setIsSearchOpen={setIsSearchOpen}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 8, offset: 1 }} className="nav-bar-col">
                <NavBar links={data?.mainMenu?.links} />
              </Col>
              <Col className="donate-button-col" style={{ padding: 0 }}>
                <DonateButton to={getLink(data?.donatePage)} large />
              </Col>
            </Row>
          </Container>
        </StyledHeader>
      );
    } else {
      return (
        <MobileHeader>
          <Container fluid>
            <Row>
              <Col className="burger-logo-col">
                <Burger
                  links={data?.mainMenu?.links}
                  footerLinks={data?.footer?.links}
                  isBurgerOpen={isBurgerOpen}
                  toggleBurger={toggleBurger}
                  swirlImage={data?.mainMenu?.headerImage}
                  global={data?.global}
                  contactPage={data?.contactPage}
                />
                <WmkLink to="/">
                <ITLogo width="114" height="70" />
                </WmkLink>
              </Col>
            </Row>
            <Row>
              <Col className="donate-button-col">
                <DonateButton to={getLink(data?.donatePage)} header>
                  Donate Today
                </DonateButton>
              </Col>
            </Row>
          </Container>
        </MobileHeader>
      );
    }
  };

  return <>{getDisplay()}</>;
};

export default Header;
