import { gatsbyImg, Img } from "./Img";
import { RichText } from "./RichText";

export interface page {
  content: {
    raw: string;
    json: { nodeType: string; data: object; content: object[] };
    references: [];
  };
  heroHeader: string;
  heroImage: gatsbyImg;
  heroSubheader: string;
  metaDescription: string;
  parentPath: string;
  slug: string;
  title: string;
}

export class Page {
  content: {
    raw: string;
    json: { nodeType: string; data: object; content: object[] };
    references: [];
  };
  heroHeader: string;
  heroImage: Img;
  heroSubheader: string;
  metaDescription: string;
  parentPath: string;
  slug: string;
  title: string;
  constructor(node: page) {
    const _node = {...node}
    this.content = new RichText(_node.content);
    this.heroHeader = _node.heroHeader;
    this.heroSubheader = _node.heroSubheader;
    this.metaDescription = _node.metaDescription;
    this.parentPath = _node.parentPath;
    this.slug = _node.slug;
    this.title = _node.title;
    this.heroImage = new Img(_node.heroImage);
  }
}
