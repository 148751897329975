import * as React from "react";
import styled from "styled-components";
import BurgerButton from "./BurgerButton";
import BurgerDrawer from "./BurgerDrawer";
import { menuPage } from "../../ui/NavBar";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100vw;
  justify-content: center;
`;

const Burger = ({
  links,
  footerLinks,
  isBurgerOpen,
  toggleBurger,
  swirlImage,
  global,
  contactPage
}: {
  links: [{ title: string; parentPage: menuPage; links: menuPage[] }];
  footerLinks: [{ title: string; parentPage: menuPage; links: menuPage[] }];
  isBurgerOpen: boolean;
  toggleBurger: Function;
  global: {
    title: string;
    address: {
      address: string;
    };
    phone: string;
    contactEmail: string;
  };
  contactPage: {
    parentPath: string,
    slug: string,
    title: string
  }
  swirlImage: {
    title: string;
    gatsbyImageData: {
      height: number;
      images: {
        fallback: {
          sizes: string;
          src: string;
          srcSet: string;
        };
        sources: {
          sizes: string;
          srcSet: string;
          type: string;
        }[];
      };
      layout: "fixed" | "fullWidth" | "constrained";
      width: number;
    };
  };
}) => {
  return (
    <Wrap>
      <BurgerButton toggle={toggleBurger} isOpen={isBurgerOpen} />
      <BurgerDrawer
        links={links}
        footerLinks={footerLinks}
        isOpen={isBurgerOpen}
        swirlImage={swirlImage}
        global={global}
        contactPage={contactPage}
      />
    </Wrap>
  );
};

export default Burger;
