import * as React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import get from "lodash/get";
import { Col, Row } from "react-bootstrap";
import { WmkLink } from "wmk-lib";
import { colors } from "./colorVariables";
import { page, Page } from "../../classes/Page";
import { Typography } from "./Typography";

interface Props {
  mobile?: boolean | null;
}

const Results = styled.div<Props>`
  position: absolute;
  height: auto;
  max-height: ${({ mobile }) => (mobile ? "50vh" : "70vh")};
  overflow: scroll;
  top: ${({ mobile }) => (mobile ? "4rem" : "6vh")};
  left: ${({ mobile }) => (mobile ? 0 : "-40%")};
  width: ${({ mobile }) => (mobile ? "100%" : "150%")};
  z-index: 100;
  & > .row {
    background: rgba(255, 255, 255, 0.95);
    padding: 3vh 3vw;
  }
  a {
    color: ${get(colors, "blue.hex")};
    text-decoration: none;
    padding-bottom: 0.25rem;
    margin-bottom: 0.25rem;
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    &:hover {
      color: ${colors.black.hex};
    }
  }
`;

const rawQuery = graphql`
  {
    pages: allContentfulPage {
      edges {
        node {
          ...NodePageFields
        }
      }
    }
  }
`;

const SearchResults = ({ query, mobile }: {query: string, mobile: boolean | undefined}) => {
  const data = useStaticQuery(rawQuery);
  const results = Object.keys(data)
    .reduce((all: page[], type) => {
      switch (type) {
        case "pages":
          const pagesData = get(data, `[${type}].edges`);
          const pgs = pagesData.map((page: {node: page}) => {
            return new Page(get(page, `node`));
          });
          pgs.reduce((matches: [], pg: page) => {
            const regex = new RegExp(query, "ig");
            if (pg.title.match(regex)) {
              all.push(pg);
            }
            return matches;
          }, []);
          break;
        default:
          console.log(`error: unrecognized content model`);
      }
      return all;
    }, [])
    .sort((a, b) => {
      const titleA = get(a, `title`, get(a, `displayTitle`));
      const titleB = get(b, `title`, get(b, `displayTitle`));
      return titleA > titleB ? 1 : titleA < titleB ? -1 : 0;
    });

  return query ? (
    <Results mobile={mobile}>
      {results.length > 0 ? (
        <Row className="flex-column">
          {results.map((res, i) => {
            const title = get(res, `title`, get(res, `displayTitle`));
            const parentPath = get(res, "parentPath");
            const slug = get(res, "slug");
            const fullPath =
            parentPath && slug && parentPath !== "/"
            ? `${parentPath}/${slug}`
            : slug
            ? `/${slug}`
            : `#`;
            const to = fullPath;
            return (
              <Col key={title + i}>
                <WmkLink to={to}>
                  <Typography.P>
                    {title}
                    </Typography.P>
                  </WmkLink>
              </Col>
            );
          })}
        </Row>
      ) : (
        <Row>
          <Col style={{ color: colors.blue.hex }}>
            No matching results for {query}
          </Col>
        </Row>
      )}
    </Results>
  ) : null;
};

export default SearchResults;
