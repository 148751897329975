import * as React from "react";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import ITLogo from "../ITLogo";
import { Typography } from "../ui/Typography";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import get from "lodash/get";
import { colors } from "../ui/colorVariables";
import { getLinkPath } from "../ui/NavBar";
import { WmkLink } from "wmk-lib";
import { useState, useEffect } from "react";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaPinterest
} from "react-icons/fa";

const IconList = {
  facebook: <FaFacebook />,
  twitter: <FaTwitter />,
  pinterest: <FaPinterest />,
  linkedin: <FaLinkedin />,
  instagram: <FaInstagram />
};

const FooterWrapper = styled.footer`
  .container-fluid {
    padding: 0;
  }
  .logo-col {
    position: absolute;
    top: 0;
    left: 0;
  }
  .logo-col > * {
    position: absolute;
    top: 9vw;
    left: 7vw;
  }
  .links-row {
    padding-left: 7vw;
    min-height: 40vh;
    width: 100%;
  }
  .col {
    padding-right: 0;
  }
  .social-icon-link {
    padding: 0 0.5rem;
    top: -5px;
    position: relative;
    p {
      line-height: 1rem;
      margin-bottom: 0;
      color: ${colors.blue.hex};
    }
  }
  .social-icon-link:hover {
    p {
      color: ${colors.darkBlue.hex};
    }
  }
`;

const StyledLinksCol = styled(Col)`
  p {
    margin: 0;
    color: ${get(colors, "black.hex")};
  }
  a {
    text-decoration: none;
  }
  a:hover {
    p {
      color: ${colors.blue.hex};
    }
  }
  hr {
    display: block;
    // height: 1px;
    border: 0;
    border-top: 2px solid ${get(colors, "blue.hex")};
    margin: 0.5rem 0;
    padding: 0;
    width: 50%;
  }
  .top-link {
    color: ${colors.blue.hex};
    p {
      color: ${colors.blue.hex};
    }
  }
  .top-link:hover {
    p {
      color: ${colors.darkBlue.hex};
    }
  }
  .phone,
  .email {
    p {
      color: ${colors.darkBlue.hex};
    }
  }
  .phone:hover,
  .email:hover {
    p {
      color: ${colors.blue.hex};
    }
  }
`;

const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      mainMenu: contentfulMenu(title: { eq: "Footer Menu" }) {
        ...NodeMenuFields
      }
      contactPage: contentfulPage(title: { eq: "Contact Us" }) {
        parentPath
        slug
        title
      }
      global: contentfulGlobalOptions(title: { eq: "India Transformed!" }) {
        title
        address {
          address
        }
        phone
        contactEmail
      }
    }
  `);

  const [viewWidth, setViewWidth] = useState(0);
  useEffect(() => {
    const handleResize = () => {
      const winWidth = window.innerWidth;
      setViewWidth(winWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const links = data.mainMenu?.links;
  const address = data.global?.address?.address
    ? data.global.address.address.split("\n")
    : "";

  return (
    <FooterWrapper>
      {viewWidth > 992 && (
        <Container fluid>
          <Row
            style={{ position: "relative", margin: "0 0 10vw", width: "100%" }}>
            <Col style={{ padding: 0 }}>
              <GatsbyImage
                image={get(data, "mainMenu.headerImage.gatsbyImageData")}
                alt={get(data, "mainMenu.headerImage.title")}
              />
            </Col>
            <Col className="logo-col">
              <ITLogo
                width={(viewWidth * 0.3).toString()}
                height={(viewWidth * 0.3 * 0.6116).toString()}
              />
            </Col>
          </Row>
          <Row className="links-row">
            <StyledLinksCol>
              <WmkLink
                to={getLinkPath(get(data, "contactPage"))}
                style={{ display: "block" }}
                className="top-link">
                <Typography.Footer
                  style={{
                    fontWeight: 400
                  }}>
                  {get(data, "contactPage.title")}
                </Typography.Footer>
              </WmkLink>
              <hr />
              <Typography.Footer>{address[0]}</Typography.Footer>
              <Typography.Footer>{address[1]}</Typography.Footer>
              <WmkLink
                style={{ display: "block" }}
                tel
                to={data.global?.phone}
                className="phone">
                <Typography.Footer>{data.global.phone}</Typography.Footer>
              </WmkLink>
              <WmkLink
                style={{ display: "block" }}
                mailto
                to={data.global?.contactEmail}
                className="email">
                <Typography.Footer>
                  {data.global.contactEmail}
                </Typography.Footer>
              </WmkLink>
            </StyledLinksCol>
            {Array.isArray(links) &&
              links.length &&
              links.map((link, i) => {
                if (link?.title === "Social Media") {
                  return (
                    <Col key={`${get(link, "title")} + ${i}`}>
                      {Array.isArray(link.links) &&
                        link.links.length &&
                        link.links.map(
                          (
                            sublink: {
                              linkText: string;
                              url: string;
                            },
                            j: number
                          ) => {
                            const icon = IconList[sublink?.linkText];
                            return (
                              <WmkLink
                                to={sublink.url}
                                style={{ display: "inline-block" }}
                                key={`${sublink.linkText} + ${j}`}
                                className="social-icon-link">
                                <Typography.Footer
                                  style={{
                                    fontWeight: 400,
                                    fontSize: "1.5rem"
                                  }}>
                                  {icon ? icon : <></>}
                                </Typography.Footer>
                              </WmkLink>
                            );
                          }
                        )}
                    </Col>
                  );
                } else {
                  return (
                    <StyledLinksCol key={`${link.title} + ${i}`}>
                      <WmkLink
                        to={getLinkPath(link?.links[0])}
                        style={{ display: "block" }}
                        className="top-link">
                        <Typography.Footer
                          style={{
                            fontWeight: 400
                          }}>
                          {get(link, "title")}
                        </Typography.Footer>
                      </WmkLink>
                      <hr />
                      {Array.isArray(link?.links) &&
                        link.links.length &&
                        link.links.map(
                          (
                            sublink:
                              | {
                                  parentPath: string;
                                  slug: string;
                                  title: string;
                                }
                              | {
                                  linkId: string;
                                  linkText: string;
                                  url: string;
                                },
                            j: number
                          ) => {
                            const isPageLink = "title" in sublink ? true : false
                            const text = "title" in sublink ? sublink.title : sublink.linkText
                            return (
                              <WmkLink
                                to={isPageLink ? getLinkPath(sublink) : sublink.url}
                                style={{ display: "block" }}
                                key={`${text} + ${j}`}>
                                <Typography.Footer>
                                  {text}
                                </Typography.Footer>
                              </WmkLink>
                            );
                          }
                        )}
                    </StyledLinksCol>
                  );
                }
              })}
          </Row>
        </Container>
      )}
    </FooterWrapper>
  );
};

export default Footer;
