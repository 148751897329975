import get from "lodash/get";

export interface gatsbyImg {
  title: string;
  gatsbyImageData: {
    images: {
      sources: { sizes: string; srcSet: string; type: string }[];
      fallback: { sizes: string; srcSet: string; src: string };
    };
    height: number;
    width: number;
    layout: "fixed" | "fullWidth" | "constrained";
  };
}
/**
 * @class
 * Helper class for image queries that will
 * hold data for Static/Dynamic images
 * (via Gatsby)
 */
export class Img {
  /**
   * @param {Object} node - result of an image node query from Contentful
   */
  title: string;
  alt: string;
  src: string | undefined;
  gatsby: {
    images: {
      sources: { sizes: string; srcSet: string; type: string }[];
      fallback: { sizes: string; srcSet: string; src: string };
    };
    layout: "fixed" | "fullWidth" | "constrained";
    backgroundColor?: string;
    width: number;
    height: number;
  };
  contentType: string;
  url: string | undefined;
  constructor(node: gatsbyImg) {
    const gatsby = node?.gatsbyImageData;
    const _url = get(
      node,
      `file.url`,
      get(node, `node.url`, get(node, `gatsbyImageData.images.fallback.src`))
    );
    const url =
      typeof _url === "string"
        ? _url.indexOf("//") === 0
          ? "https:" + _url
          : _url
        : undefined;
    this.title = get(node, `title`, get(node, `node.title`));
    this.alt = get(node, `title`, get(node, `node.altText`, ""));
    this.src = url;
    this.gatsby = gatsby;
    this.contentType = get(node, `file.contentType`);
  }
}
