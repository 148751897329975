import * as React from "react";
import styled from "styled-components";
import { colors } from "../../ui/colorVariables";
import get from "lodash/get";

interface Props {
  lineBaseStyle?: string;
  isOpen: boolean;
}

const BurgerWrap = styled.button<Props>`
  position: ${({ isOpen }) => isOpen ? `fixed` : `absolute`};
  padding: 0 1rem;
  top: ${({ isOpen }) => isOpen ? `2vh` : `5vh`};
  height: 40px;
  width: 40px;
  cursor: pointer;
  z-index: 1000;
  transition: opacity 0.25s ease;
  background: none;
  border: none;
  ${({ isOpen }) =>
    isOpen ? `left: calc(75vw - 1.5rem);` : ``}
    ${({ isOpen }) =>
    isOpen ? `@media only screen and (max-width: 992px) {
      left: calc(45vw - 1.5rem);
    }
    @media only screen and (max-width: 768px) {
      left: calc(55vw - 1.5rem);
    }
    @media only screen and (max-width: 576px) {
      left: calc(74vw - 1.5rem);
    }` : ``}
`;

const TopBar = styled.span<Props>`
  ${({ lineBaseStyle }) => lineBaseStyle}
  ${({ isOpen }) =>
    isOpen ? `transform: rotate(45deg); left: 0; top: 20px;`
      : `left: 0; top: 7px;`}
`;
const MidBar = styled.span<Props>`
  ${({ lineBaseStyle }) => lineBaseStyle}
  ${({ isOpen }) =>
    isOpen ? `transform: rotate(180deg); left: 0; top: 20px; opacity: 0;`
      : `top: 20px;`}
`;
const BtmBar = styled.span<Props>`
  ${({ lineBaseStyle }) => lineBaseStyle}
  ${({ isOpen }) =>
    isOpen ? `transform: rotate(-45deg); left: 0; top: 20px;`
      : `left: 0; top: 33px;`}
`;

const BurgerButton = ({
  isOpen,
  toggle,
}: {isOpen: boolean, toggle: Function}) => {
  const lineBaseStyle = `
  background: ${get( colors, "black.hex")};
  border: none;
  height: 5px;
  width: 100%;
  border-radius: 2px;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.35s ease;
  cursor: pointer;
`;
  return (
    <BurgerWrap
      onClick={()=>toggle()}
      // style={{ top: `${height / 2 / 2}px` }}
      aria-label={isOpen ? "Close Menu" : "Open Menu"}
      isOpen={isOpen}
    >
      <TopBar isOpen={isOpen} lineBaseStyle={lineBaseStyle} />
      <MidBar isOpen={isOpen} lineBaseStyle={lineBaseStyle} />
      <BtmBar isOpen={isOpen} lineBaseStyle={lineBaseStyle} />
    </BurgerWrap>
  );
};

export default BurgerButton;