import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import { WmkLink } from "wmk-lib";
import { colors } from "../../ui/colorVariables";
import { Typography } from "../../ui/Typography";
import { menuPage } from "../../ui/NavBar";
import { AiOutlineHome } from "react-icons/ai";
import { BsPeople, BsHeart } from "react-icons/bs";
import { IoHandRightOutline } from "react-icons/io5";
import { Col, Container, Row } from "react-bootstrap";
import ITLogo from "../../ITLogo";
import { GatsbyImage } from "gatsby-plugin-image";
import { FaChevronDown, FaFacebookF } from "react-icons/fa";
import DonateButton from "../../ui/DonateButton";

const Wrap = styled.div`
  background: ${colors.footerBlue.hex};
  color: #a7a7a7;
  width: 80vw;
  height: 100%;
  position: fixed;
  left: 0;
  transition: all 0.3s ease;
  box-shadow: 0 5px 25px;
  overflow-y: scroll;
  &.open {
    top: 0;
    opacity: 1;
    transition: top 0.1s ease, opacity 0.3s ease 0.1s;
    z-index: 11;
  }
  &.closed {
    top: -30rem;
    opacity: 0;
    transition: opacity 0.3s ease, top 0.1s ease 0.3s;
    z-index: -1;
  }
  .footer-row {
    background: ${colors.footerBlue.hex};
    min-height: 10vh;
    width: 100%;
    padding: 9vh calc(var(--bs-gutter-x)) 2rem;
    padding-top: 1rem;
    a {
      text-decoration: none;
      color: white;
    }
  }
  .logo-col {
    margin: 1rem 0;
    padding: calc(var(--bs-gutter-x) * 1);
    padding-top: 0;
  }
  .row {
    margin: 0 !important;
  }
  @media only screen and (max-width: 992px) {
    width: 50vw;
  }
  @media only screen and (max-width: 768px) {
    width: 60vw;
  }
  @media only screen and (max-width: 576px) {
    width: 80vw;
  }
`;

const StyledMobileMenuItemLink = styled.button`
  border: none;
  background: none;
  padding: 1rem 0;
  padding-left: 1.5rem;
  text-align: start;
  text-transform: uppercase;
  width: 100%;
  color: ${colors.gray.hex};
  &.submenu-item {
    margin: 0.5rem 0 !important;
  }
  &:hover {
    color: ${colors?.blue?.hex};
    background: ${colors.lightGray.hex};
  }
  a {
    color: #a7a7a7;
    text-decoration: none;
    max-width: 100vw;
    div {
      max-width: 100vw !important;
    }
  }
  & > div {
    transition: all 0.3s ease;
  }
  .text-col {
    text-align: start;
    align-self: center;
    h5 {
      margin: 0;
    }
  }
  .chevron {
    color: ${colors.green.hex};
    transition: all 0.3s ease;
    transform: ${({ open }) =>
      open ? "scale(1) rotate(180deg)" : "scale(1) rotate(0)"};
  }
`;

const StyledDropdownMenu = styled(Col)<Props>`
  padding-left: 0;
  padding-right: 0;
  left: 0;
  top: 8.8rem;
  transition: all 0.3s ease;
  opacity: ${({ open }) => (open ? 1 : 0)};
  transform: ${({ open }) => (open ? "positionY(-100vw)" : "positionY(0)")};
  z-index: ${({ open }) => (open ? 1 : -1)};
  background: ${colors.lightGray.hex};
  //box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  .menu-item-col {
    padding: 0;
  }
  .menu-item-col > *:before {
    content: "";
  }
  .dropdown-container: {
    padding-left: 0;
    padding-right: 0;
  }
  @media only screen and (max-width: 1200px) {
    top: 8.2rem;
  }
`;
const MobileMenuLink = styled(Col)`
  text-transform: uppercase;
  a {
    text-decoration: none;
    color: ${colors?.gray?.hex};
    &:hover {
      color: ${colors?.blue?.hex};
    }
    h5 {
      margin: 0;
    }
    .icon-links {
      height: 3rem;
    }
    .icon-links > div {
      display: flex;
      justify-content: start;
      align-items: center;
    }
    .icon-links > .col-2 {
      justify-content: center;
    }
  }
`;

const StyledMenuLink = styled(Col)<Props>`
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  h5 {
    color: ${({ xs }) => (xs ? colors?.blue?.hex : `inherit`)};
    margin: 0;
    padding: 1.5rem 3rem;
  }
  a {
    text-decoration: none;
    color: black;
    width: 100%;
    &:hover {
      color: ${colors?.blue?.hex};
    }
  }
`;

const StyledLinksCol = styled(Col)`
  padding-bottom: 2rem;
  p {
    margin: 0;
    color: white;
  }
  a > p {
    text-decoration: underline;
  }
  a:hover {
    p {
      color: white;
    }
  }
  hr {
    display: block;
    // height: 1px;
    border: 0;
    border-top: 2px solid white;
    margin: 0.5rem 0;
    padding: 0;
    width: 50%;
    opacity: 1;
  }
  .top-link {
    color: white;
    p {
      color: white;
    }
  }
  .top-link:hover {
    p {
      color: ${colors.darkBlue.hex};
    }
  }
  .phone,
  .email {
    p {
      color: white;
    }
  }
  .phone:hover,
  .email:hover {
    p {
      color: ${colors.blue.hex};
    }
  }
`;

const MenuItemLink = ({
  to,
  text,
  xs,
  i
}: {
  to: string;
  text: string;
  xs?: string;
  i?: number;
}) => {
  const icons = [
    <AiOutlineHome />,
    <BsPeople />,
    <BsHeart />,
    <IoHandRightOutline />
  ];
  return (
    <StyledMenuLink xs={xs} to={to}>
      <WmkLink to={to} style={{ display: "block" }}>
        <Typography.H5 style={{ fontSize: ".95rem" }}>{text}</Typography.H5>
      </WmkLink>
    </StyledMenuLink>
  );
};

const DropdownMenu = ({
  open,
  pageLinks,
  to,
  parentTitle
}: {
  open: string | null;
  pageLinks: menuPage[];
  to: string;
  parentTitle: string;
}) => {
  return open === to ? (
    <StyledDropdownMenu open={open === to ? open : null} to={to}>
      <Container
        fluid
        className="dropdown-container"
        style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Row>
          {Array.isArray(pageLinks) && pageLinks.length
            ? pageLinks.map((link, i) => {
                return (
                  <Col
                    key={link.title + i}
                    md={12}
                    className="menu-item-col"
                    style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <MenuItemLink
                      to={link.url ? link.url : getLinkPath(link)}
                      text={
                        link.title === parentTitle
                          ? "Overview"
                          : link.title
                          ? link.title
                          : link.linkText
                      }
                      // target={get(link, `target)`)}
                      i={i}
                    />
                  </Col>
                );
              })
            : null}
        </Row>
      </Container>
    </StyledDropdownMenu>
  ) : null;
};

const MobileSubMenu = ({
  text,
  to,
  links,
  openDropdownSelection,
  handler,
  i,
  title
}: {
  text: string;
  to: string;
  links: menuPage[];
  openDropdownSelection: string | null;
  handler: Function;
  i: number;
  title: string;
}) =>
  //   {
  //   text,
  //   to,
  //   i
  // }: {
  //   text: string;
  //   to: string;
  //   i: number;
  // }
  {
    const icons = [
      <AiOutlineHome />,
      <BsPeople />,
      <BsHeart />,
      <IoHandRightOutline />
    ];
    return (
      <>
        <StyledMobileMenuItemLink
          onClick={() => handler(to)}
          open={openDropdownSelection === to ? openDropdownSelection : ""}>
          <Row className="icon-links">
            <Col xs={2}>
              <Typography.H5 style={{ fontSize: "2rem" }}>
                {i ? icons[i] : icons[2]}
              </Typography.H5>
            </Col>
            <Col xs={9} className="text-col">
              <Typography.H5 style={{ fontSize: ".95rem" }}>
                {text}
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    marginLeft: "0.5rem"
                  }}
                  className="chevron">
                  <FaChevronDown />
                </div>
              </Typography.H5>
            </Col>
          </Row>
        </StyledMobileMenuItemLink>
        <DropdownMenu
          open={openDropdownSelection}
          pageLinks={links}
          to={to}
          parentTitle={title}
        />
      </>
    );
  };

const getLinkPath = (link: menuPage) => {
  const parentPath = link?.parentPath;
  const slug = link?.slug;
  const fullPath =
    parentPath && slug && parentPath !== "/"
      ? `${parentPath}/${slug}`
      : slug
      ? `/${slug}`
      : `#`;
  return fullPath;
};

const Intermediary = ({
  links
}: {
  links: [{ title: string; parentPage: menuPage; links: menuPage[] }];
}) => {
  const [isOpen, setIsOpen] = useState<string | null>(null);

  const handleDropdown = (to: string | null) => {
    if (to === isOpen) {
      setIsOpen(null);
    } else {
      setIsOpen(to);
    }
  };

  return (
    <>
      {Array.isArray(links) &&
        links.length &&
        links.map((link, i) => {
          return (
            <MobileSubMenu
              // text={link?.parent?.text ? link?.parent?.text : link?.title}
              // key={`${link?.title}-${i}`}
              // to={getLinkPath(link?.parentPage)}
              // i={i}
              links={link.links}
              text={link.title}
              to={getLinkPath(link.parentPage)}
              openDropdownSelection={isOpen}
              key={`${link.title} ${i}`}
              handler={handleDropdown}
              i={i}
              title={link.title}
            />
          );
        })}
    </>
  );
};

const BurgerDrawer = ({
  links,
  footerLinks,
  isOpen,
  swirlImage,
  global,
  contactPage
}: {
  links: [{ title: string; parentPage: menuPage; links: menuPage[] }];
  footerLinks: [{ title: string; parentPage: menuPage; links: menuPage[] }];
  isOpen: boolean;
  global: {
    title: string;
    address: {
      address: string;
    };
    phone: string;
    contactEmail: string;
  };
  contactPage: {
    parentPath: string;
    slug: string;
    title: string;
  };
  swirlImage: {
    title: string;
    gatsbyImageData: {
      height: number;
      images: {
        fallback: {
          sizes: string;
          src: string;
          srcSet: string;
        };
        sources: {
          sizes: string;
          srcSet: string;
          type: string;
        }[];
      };
      layout: "fixed" | "fullWidth" | "constrained";
      width: number;
    };
  };
}) => {
  const address = global?.address?.address
    ? global.address.address.split("\n")
    : "";
  return (
    <Wrap className={isOpen ? "open" : "closed"}>
      <Container fluid style={{ padding: 0, background: "white" }}>
        {/* <Search mobile /> */}
        <Row>
          <Col className="logo-col">
            <ITLogo width="114" height="70" />
          </Col>
        </Row>
        <Row>
          <Intermediary links={links} />
          {/* {links.map((link, i) => {
            return (
              <MobileSubMenu
                text={link?.parent?.text ? link?.parent?.text : link?.title}
                key={`${link?.title}-${i}`}
                to={getLinkPath(link?.parentPage)}
                i={i}
              />
            );
          })} */}
        </Row>
        <Row>
          <GatsbyImage
            image={swirlImage?.gatsbyImageData}
            alt={swirlImage?.title}
            style={{ transform: "scaleY(-1) scaleX(-1)" }}
          />
        </Row>
        <Row className="footer-row">
          <StyledLinksCol>
            <WmkLink
              to={getLinkPath(contactPage)}
              style={{ display: "block" }}
              className="top-link">
              <Typography.Footer
                style={{
                  fontWeight: 400,
                  textDecoration: "none"
                }}>
                {contactPage?.title}
              </Typography.Footer>
            </WmkLink>
            <hr />
            <Typography.Footer>{address[0]}</Typography.Footer>
            <Typography.Footer>{address[1]}</Typography.Footer>
            <WmkLink
              style={{ display: "block" }}
              tel
              to={global?.phone}
              className="phone">
              <Typography.Footer>{global.phone}</Typography.Footer>
            </WmkLink>
            <WmkLink
              style={{ display: "block" }}
              mailto
              to={global?.contactEmail}
              className="email">
              <Typography.Footer>{global.contactEmail}</Typography.Footer>
            </WmkLink>
          </StyledLinksCol>
          <DonateButton to={"/donate-today"} white>
            Donate Today
          </DonateButton>
          {/* {footerLinks.map((link, i) => {
            console.log("footerLinks", footerLinks)
            console.log("links", links)
            
            if (link?.title === "Social Media Menu") {
              return (
                <Col
                  key={`${link?.title}-${i}`}
                  style={{ position: "absolute", bottom: 0 }}>
                  <WmkLink
                    to={link?.links[0]?.url}
                    target={link?.links[0]?.url}
                    style={{ display: "block" }}>
                    <Typography.Footer
                      style={{
                        fontWeight: 400,
                        fontSize: "1.5rem"
                      }}>
                      <FaFacebookF />
                    </Typography.Footer>
                  </WmkLink>
                </Col>
              );
            } else {
              return (
                <Col xs={6} key={`${link?.title}-${i}`}>
                  <WmkLink
                    to={getLinkPath(link?.parentPage)}
                    style={{ display: "block" }}>
                    <Typography.Footer
                      style={{
                        fontWeight: 400
                      }}>
                      {link?.title}
                    </Typography.Footer>
                  </WmkLink>
                </Col>
              );
            }
          })} */}
        </Row>
      </Container>
    </Wrap>
  );
};

export default BurgerDrawer;
